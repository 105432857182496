//Custom
//Custom
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $fiduc-scroll-bar-bg #ff0000;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $fiduc-scroll-bar-bg;
    border-radius: 10px;
    border: 3px  solid transparent;
  }


//Custom

:root {
  --bs-fiduc-bg: #f1efe9;
  --bs-fiduc-gray-dark: #f1efe9;
  --bs-fiduc-gray-light: #f1efe9;
  --bs-fiduc-green: #{$fiduc-success};
}

@keyframes inAnimation {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outAnimation {
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity:0;
  }

  to {
    transform: translateX(0%);
    opacity:1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0%);
    opacity:1;
  }

  to {
    transform: translateX(100%);
    opacity:0;
  }
}

@keyframes opacityIn {
  from {
    opacity:0;
  }

  to {
    opacity:1;
  }
}

@keyframes opacityOut {
  from {
    opacity:1;
  }

  to {
    opacity:0;
  }
}


BODY.page-ready .form-field-animations-in {
    animation-name: slideIn;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 300ms;
}

BODY.page-ready .form-field-animations-out {
    animation-name: slideOut;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 300ms;
}


.form-field-layout-floating-label {
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: inherit;
    padding: 1px;
    pointer-events: none;
}

.form-group INPUT, SELECT, TEXTAREA {
  height:46px;
  max-height:46px;
}

TEXTAREA {
  height:46px;
  max-height:unset !important;
}

.form-group INPUT[type=radio], .form-group INPUT[type=checkbox] {
  height:1em;
}

.form-field-col-daterange {
	height: 3rem;
}

.icon-circle {
	border-radius: 50%;
	empty-cells: show;
	width: .5rem;
	height:.5rem;
	font-size:1px;
	line-height: 1px;
}


//.form-group > .form-field-layout-floating-label {
.form-field-floating-label-blur {
    transition:  0.3s ease;
    position: relative;
    top: 12px;
    left: 1em;
    overflow: hidden;
    padding: 0px 4px 0px 4px;
    border-radius: 6px;
    font-size: .9rem;
    xpointer-events: none;
    color:$gray-400;
	    line-height: 160%;
}

#sidebar-menu {
	background-color: $fiduc-blue ;
}

.floating-label-container {
  position: absolute;
  pointer-events: none;
  z-index: 1;

}
.form-wizard {
	text-align: left;
}

.page-content {
	padding-bottom: 0px;
	margin-bottom: 0px;
}
.page-content-full-height {
	padding: 1em calc(24px / 2) 0px calc(24px / 2) !important;
	overflow: auto !important;
	height: calc(100vh - 80px) !important;
	border:solid 2px red;
}

.datatable-container {
	display: grid;
	height: calc(100vh - 200px) !important;
}

.datatable-bottom-float {
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.datatable-bottom-float > DIV:nth-child(2)  {
    position: sticky;
    bottom: 0;
	padding-bottom: 0px;
	margin-bottom: 0px;
    align-self: start;
}

.rdt_TableHead {
    position: sticky;
    top: 0;
    align-self: start;
	xborder:solid 1px red;
	z-index: 1;
	width: 100%;
}

.datatable-filter-container {
	box-shadow: $box-shadow;
 }


.rdt_TableCell {
	padding: 0.6em 2px 0.6em 2px !important;
}

.debug-on .debug{outline:solid 2px red;}
.debug-on .debug1{outline:solid 2px deeppink;}
.debug-on .debug2{outline:solid 2px green;}
.debug-on .debug3{outline:solid 2px yellow;}
.debug-on .debug4{outline:solid 2px #00FEFF;}
.debug-on .debug5{outline:solid 2px blue;}
.debug-on .debug6{outline:solid 2px #cbe4fb;}

.text-align-left {
	text-align: left !important;
}
.text-align-right {
	text-align: right !important;;
}
.text-align-center {
	text-align: center !important;;
}

.border-light {
	border-color:$fiduc-light-gray !important;
}

.border-dark {
	border-color:$fiduc-gray-dark !important;
}

.rdt_TableCol_Sortable>div {
	white-space: nowrap;
}


.form-wizard-searchBar {
	width:100%;
	padding-top: 1em;
}

#form-control-searchString {
	margin-bottom: 0px !important;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
	//min-height: unset !important;
	min-height: 6.2em !important;
}

 .dropzone .dz-message   {
	 cursor:pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
      padding-top: 0.5em;
	min-height: unset !important;
}

.form-field-type-upload > .text-danger.form-group > :not(invalid-feedback-upload) {
  display:none;
}

.form-field-type-daterange INPUT.form-control {
	padding: 0px;
    margin: 0px;
    height: 26px;
    border: solid 0px red;
    background-color: white;
}

.form-field-type-password INPUT[type="text"] {
	font-family: 'password';
}

.form-field-type-select select option:first-child
/*.form-field-type-select select:not(select[value])*/
{
  color: lightgray;

}

.form-field-type-select select,
.form-field-type-date input
/*.form-field-type-radiogroup fieldset*/
{
	padding: 0.75rem .47rem 0.47rem .75rem;
}

.form-field-type-upload .invalid-feedback-upload .invalid-feedback {
  display: block;
  font-size: 0.6em;
  font-weight: initial;
}


.dropzone .dz-image-preview {
    display:flex;
    align-items: end;
}

.dz-message {
  padding-top:1em !important;
}

.dropzone-container {
	display: flex !important;
	align-items: stretch;
	flex-grow: 1;
	flex:1;
	justify-content: space-around;
	//border:solid 1px greenyellow;
    margin: 0;
	padding-right: 0rem !important;
	padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-right: 1rem !important;
    margin-left: 1rem !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.dz-text-muted {
    --bs-text-opacity: 1;
  font-size:0.75em;
}

.dz-text-selected {
  color:$gray-800;
    cursor:inherit;
  --bs-text-opacity: 1;
  font-size:0.5em;
  text-align: left;
}


.custom-header-filter-icon {
    border: solid 1px transparent;
	border-radius:6px;
    padding: 2px 4px 2px 4px;
	color: inherit;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 1;
	background-color: $white;
}

.custom-header-filter-icon:hover {
    border: outset 1px var(--bs-warning);
	transform: scale(1.3);
	z-index: 2;
}


.btn:hover .spinner-grow {
	color:white !important;
}

.rdt_Table {
	padding: 0px .2rem 0px .2rem;
}

.search-page-container {
    border-color: red #f1efe9 #f1efe9 #f1efe9 ;
    border-width: 0px 1px 1px 1px;
    /* margin: 0; */
    /* border-color: red; */
    border-style: solid;
    background-color: white;
}

//.rdt_Table .rdt_TableRow:has(input[type=checkbox]:checked) {
//	background-color: red !important;
//}

.form-field-type-radiogroup fieldset>.form-control,
.form-field-type-checkgroup fieldset>.form-control
{
	background-color: inherit !important;
}

.dz-text-selected .dz-text-filesize {
    cursor:inherit;
  --bs-text-opacity: 1;
  text-align: left;
  font-weight: normal;
}

//.form-group:focus-within > .form-field-layout-floating-label
.form-field-floating-label-focus
{
    transition:  0.3s ease;
    background-color:white;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    top: -0.70em;
    left: 0.5em;
    padding: 0px 4px 0px 4px;
    border-radius: 6px;
    font-size: 0.8em;
    xpointer-events: none;
}

.form-field-floating-label-focus[disabled],
.dropzone[disabled]
{
	background-color: $fiduc-input-bg-disabled;
}

//.form-control:not([disabled]) {
//	border-color:$fiduc-background;
//}

.form-control {
	padding-top: 0.65rem;
}

.form-field INPUT,
.form-field TEXTAREA,
.form-field SELECT,
.form-field .form-check-label,
.form-field FIELDSET,
.form-field-type-select2 .form-control-container,
.form-field-type-select2multi .form-control-container
{
	transition:  0.3s ease;
	color:$fiduc-slate;
	font-weight: 400;
}

.form-group .is-invalid, INPUT, TEXTAREA {
	transition:unset !important;
}

.form-field INPUT[disabled],
.form-field TEXTAREA[disabled],
.form-field SELECT[disabled],
.form-field FIELDSET[disabled],
.form-field-type-select2 .form-control-container[readonly],
.form-field-type-select2multi .form-control-container[readonly]
{
	transition:  0.3s ease;
	background-color: $fiduc-input-bg-disabled !important;
	color:$fiduc-slate;
	opacity: $fiduc-disabled-text-opacity;
}

.form-field .form-check.disabled INPUT[type="radio"]:checked,
.form-field .form-check.disabled INPUT[type="checkbox"]:checked
{
	background-color: $fiduc-blue !important;
	opacity: $fiduc-disabled-text-opacity;
}


BODY.sweetalert-overflow-hidden .vertical-menu {
	display: none;
}

.sweet-alert h2 {
	font-family: "PP Neue Machina";
	font-weight: 700 !important;
}

#InvestmentSearch .sweet-alert  {
	display:block !important;
}

.select2-selection__control {
	text-align: left;
}


.rdt_TableHeadRow {
	pointer-events: unset !important;
}
.bg-fiduc-blue {
	background-color: $fiduc-blue;
}

.bg-fiduc-danger {
	background-color: $fiduc-danger;
}

.bg-fiduc-light-gray {
	background-color: $fiduc-light-gray;
}

.bg-fiduc-subtable {
	background-color: $gray-100;
}

.bg-fiduc-subtable THEAD {
	background-color: $gray-200;
}

.bg-fiduc-subtable TABLE {
	margin-bottom:unset !important;
}



.sweet-alert h2:first-child:empty {
	display:none;
}

.form-field .form-field-floating-label-focus[disabled] {
	background-color:$white !important;
}


.dropzone[disabled] > * {
	cursor: default !important;
}


input.no-autofill-bkg:-webkit-autofill {
  -webkit-background-clip: text;
}



/*
  Google input style
*/
/*
INPUT.form-control {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  border-bottom-width: 2px;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  box-shadow: unset;
}

.form-control:focus, .form-control.is-valid:focus {
    border-color: $primary;
}
*/

.tab-pills-badge {
	background-color: $fiduc-orange !important;
	left: 1em !important;
	top: 1em !important;
	width: 8px;
	height: 8px;
	display: block;
	padding: 0px !important;
}

.tab-pills {
}

.tab-pills-desktop {
	min-width:10.2em;
	max-width:10.2em;
	padding-left:1em;
	padding-right:1em;
	border:solid 0px red;
}

.badge-prev {
	background-color: #228FE6;
	font-size: 0.5rem;
}

.fiduc-modal {
	//border:solid 10px red ;
	justify-content:flex-start !important;
	margin-bottom:unset !important;
	margin-top:unset !important;
}
.rounded-fiduc {
	border-radius: 0.25rem;
}

//.form-field-col-select2 {
//	width:100%;
//}

.badge-soft-danger {
	color: $fiduc-black;
	background-color: lighten($fiduc-danger, 30);
}

.badge-soft-gray {
	color: $fiduc-black;
	background-color: $fiduc-light-gray;
}



.fiduc-modal.no-title>h2 {
	//border:solid 10px red ;
	display:none;
}

//.fiduc-modal>div {
//	flex: 1 1 auto !important;
//	display: flex;
//	overflow: hidden;
//	flex-direction: column;
//}

.badge-renew-blocked {
	//background-color: $fiduc-danger;
	font-size: 0.5rem;
}

.badge-device {
	background-color: $fiduc-background;
	color:$fiduc-black;
	font-size: .8rem;
}

.badge-fatca-eua {
	background-color: $fiduc-perigo;
	font-size: 0.5rem;
}

.badge-prev-icon {
	padding-left:0.5rem;
	padding-right:0.5rem;
}

.badge-renew-status {
	position: absolute;
    right: 6em;
    z-index: 1;
}

.tab-default-badge {
	background-color: $fiduc-orange !important;
	//right: 0.5em !important;
	left:1em !important;
	top: 50% !important;
	width: 6px;
	height: 6px;
	display: block;
	padding: 0px !important;
}



.form-field-type-title, .form-field-type-collapsibletitle {
	//animation: 360ms linear 0s 1 normal none running opacityIn;

    color: $fiduc-orange;
	font-size:2em;
	margin-left: -0.2em;
    font-weight: 700;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.client-documents .dropdown-item {
	padding: .1rem 1.5rem !important;
}



#form-control-newFatcaNotFiscalResTitle {
	margin-bottom: 0 !important;
}

.text-primary {
	color:$fiduc-text-primary !important;
}

.text-secondary {
	color:$fiduc-text-secondary !important;
}

.text-danger {
	color:$fiduc-orange !important;
}

.input-switch-off {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 0.7em;
	color: #fff;
	padding-right:0px;
	margin-right:1em;
}



.input-switch-on {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-size: 0.7em;
	color: #fff;
	padding-right:0px;
	margin-left:1em;
}

.navbar-default-content {
    padding-top: 2em;
    padding-left: 0em;
    border-width: 0px 1px 0px 1px;
    border-style: solid;
    border-top-color: initial;
    border-right-color: $fiduc-nav-tabs-border-color1;
    border-bottom-color: green;
    border-left-color: $fiduc-nav-tabs-border-color1;
    border-image: initial;
}

.card-bordered {
	border:solid 1px $fiduc-gray-dark;
	border-radius:4px;
}


.nav-tabs .nav-link.active {
	//margin-bottom: -2px;
	//border-bottom-width: 0px;
}


.tab-default-badge.pulsate::before {
  top: -1px;
  left: -1px;
	border-width: 3.2px !important;
}
.tab-pills-badge.pulsate::before {
  top: -1px;
  left: -1px;
	border-width: 3.9px !important;
}

.pulsate::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  animation: pulse 1.5s ease-out ;
	animation-fill-mode: forwards;
	animation-iteration-count: 3;
  border-radius: 50%;
  border: 4px double lighten($fiduc-orange, 20%);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}



.box {
  width: 200px; height: 300px;
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
}
.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}


.client-info-thumb-nail {
	background-repeat: no-repeat;
	background-position:center;
	background-size:contain;
	height:68px;
	border-color:$fiduc-light-gray;
	border-width: 1px !important;
	border-radius:1em !important;
}

.avatar-client {
	    margin-top: 1.3em !important;
}

.text-danger LABEL, .invalid-feedback  {
	color:$fiduc-orange !important;
}


//.form-control:invalid {
//	border-color:$fiduc-orange !important;
//}




.popover-score {

}

.popover-score>.popover {
	min-width: 260px;
	margin-right:1em;
	margin-top:1em;
}


.form-field-type-daterange DIV[id] {
	width: 100%;
}

.form-field-type-daterange .select2-selection__indicators.select-date-range-menu-options SVG {
	visibility: hidden !important;
	border:solid 1px red;
}

.form-field-type-daterange .select2-selection__value-container {
	max-height : 2em !important;
}

.form-field-type-daterange .select2-selection__control {
	    margin-right: 0;
}

.form-field-type-daterange .form-control-container {
	display: flex ;
    justify-content: flex-start ;
    flex-direction: row ;
}

.form-field-type-select2 INPUT[type="text"], .form-field-type-select2multi INPUT[type="text"] {
	//color:red !important;
	padding:0px 0px 0px 0px;
	margin:0px 0px 0px 0.25rem;
	height:1em !important;
	//border:solid 1px green !important;
}

.form-field-type-select2 .form-control.is-invalid,
.form-field-type-select2multi .form-control.is-invalid {
	min-height: 3em;
}

.form-field-type-select2 .form-control.is-invalid .select2-selection__indicator svg,
.form-field-type-select2multi .form-control.is-invalid .select2-selection__indicator svg
{
    fill: $fiduc-orange !important;
}

.form-field-type-select2 .form-control-container ,
.form-field-type-select2multi .form-control-container {

}


.client-info-component .form-field {
	margin-bottom: 0px !important;
}

.btn-outline-primary:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover {
	color:white !important;
}

.btn-outline-primary .spinner-grow {
	color:$primary !important;
}

.btn-outline-primary:disabled,
.btn-outline-success:disabled,
.btn-outline-danger:disabled {
    opacity: 0.5;
	color:$gray-500 !important;
 }
.btn-xs, .btn-group-xs > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  border-radius: 0.2rem;
}

.tab-default UL {
	height: 100%;
}
.tab-default .nav-item-default {
	align-self: flex-end;
}


.btn-outline-success,
.btn-outline-primary,
.btn-outline-danger {
	border-color:rgba(0,0,0, 0.05) !important;
	color:$fiduc-black !important;;
 }

.font-weight-100 {font-weight: 100;}
.font-weight-200 {font-weight: 200;}
.font-weight-300 {font-weight: 300;}
.font-weight-400 {font-weight: 400;}
.font-weight-500 {font-weight: 500;}
.font-weight-600 {font-weight: 600;}
.font-weight-700 {font-weight: 700;}
.font-weight-800 {font-weight: 800;}
.font-weight-900 {font-weight: 900;}


.font-size-em-1 { font-size: 0.8em !important;}
.font-size-em-2 { font-size: 0.7em !important;}
.font-size-em-3 { font-size: 0.6em !important;}
.font-size-em-4 { font-size: 0.5em !important;}
.font-size-em-5 { font-size: 0.4em !important;}
.font-size-em-6 { font-size: 0.3em !important;}
.font-size-em-7 { font-size: 0.2em !important;}

.font-size-em0 { font-size: 1em !important;}
.font-size-em1 { font-size: 1.2em !important;}
.font-size-em2 { font-size: 1.3em !important;}
.font-size-em3 { font-size: 1.4em !important;}
.font-size-em4 { font-size: 1.5em !important;}
.font-size-em5 { font-size: 1.6em !important;}
.font-size-em6 { font-size: 1.7em !important;}
.font-size-em7 { font-size: 1.8em !important;}
.font-size-em8 { font-size: 1.9em !important;}
.font-size-em9 { font-size: 2.0em !important;}
.font-size-em10{ font-size: 2.2em !important;}
.font-size-em11{ font-size: 2.4em !important;}
.font-size-em12{ font-size: 2.6em !important;}
.font-size-em13{ font-size: 2.8em !important;}
.font-size-em14{ font-size: 3.0em !important;}


.font-size-rem-1 { font-size: 0.9rem !important;}
.font-size-rem-2 { font-size: 0.8rem !important;}
.font-size-rem-3 { font-size: 0.7rem !important;}
.font-size-rem-4 { font-size: 0.6rem !important;}
.font-size-rem-5 { font-size: 0.5rem !important;}
.font-size-rem-6 { font-size: 0.4rem !important;}
.font-size-rem-7 { font-size: 0.3rem !important;}
.font-size-rem-8 { font-size: 0.2rem !important;}

.font-size-rem0 { font-size: 1rem !important;}
.font-size-rem1 { font-size: 1.1rem !important;}
.font-size-rem2 { font-size: 1.2rem !important;}
.font-size-rem3 { font-size: 1.3rem !important;}
.font-size-rem4 { font-size: 1.4rem !important;}
.font-size-rem5 { font-size: 1.5rem !important;}
.font-size-rem6 { font-size: 1.6rem !important;}
.font-size-rem7 { font-size: 1.7rem !important;}
.font-size-rem8 { font-size: 1.8rem !important;}
.font-size-rem9 { font-size: 2.0rem !important;}
.font-size-rem10{ font-size: 2.2rem !important;}
.font-size-rem11{ font-size: 2.4rem !important;}
.font-size-rem12{ font-size: 2.6rem !important;}
.font-size-rem13{ font-size: 2.8rem !important;}
.font-size-rem14{ font-size: 3.0rem !important;}

.text-black-50 {
	color:rgba($fiduc-black, 0.5) !important;
}

.text-black {
	color:$fiduc-black !important;
}


.btn.btn-dark-blue {
	color: #fff;
    background-color: darken($fiduc-blue, 2) ;
    border-color: darken($fiduc-blue, 3);
}

.btn.btn-secondary {
	color: #fff;
    background-color: lighten($fiduc-blue, 15) ;
    border-color: darken($fiduc-blue, 3);
}


.btn I {
	padding-bottom:2px;
}

.pe-em2 {
  padding-left: 2.0em !important;
}

#sidebar-menu ul li a i.small {
	min-width: 1.25rem !important;
}

.btn-group-custom>button {
	border-radius: 0 !important;
	}

.btn-group-custom>button:first-child {
	border-radius: 0.5rem !important;
	border-bottom-right-radius: 0 !important;
	border-top-right-radius: 0 !important;;
	border-right-width: 0;
}

.btn-group-custom>button:last-child {
	border-radius: 0.5rem !important;
	border-bottom-left-radius: 0 !important;
	border-top-left-radius: 0 !important;;
	border-left-width: 0;
}

.dashboard-box-counter {

}

.dashboard-box-counter:hover {
	border: solid 1px $fiduc-black !important;
}
