$dot-spacing : 30px;
$color-inactive : #fff;
$color-active : gray;

/*
* ==============================================
* Dot Flashing
* ==============================================
*/
.dot-flashing {
	position: relative;
	width: 10px;
	left:0px;
	height: 10px;
	border-radius: 5px;
	background-color: $color-inactive;
	color: #9880ff;
	animation: dotFlashing 1s infinite linear alternate;
	animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-flashing::before {
	left: -$dot-spacing;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 0s;
}

.dot-flashing::after {
	left: $dot-spacing;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: $color-active;
	color: #9880ff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes dotFlashing {
	0% {
		background-color: $color-inactive;
	}
	50%,
	100% {
		background-color: $color-active;
	}
}
