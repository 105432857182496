
$font-weight-list: (
	100: "Ultralight",
	200: "Ultralight",
	300: "Light",
	400: "Regular",
	500: "Regular",
	600: "Medium",
	700: "Bold",
	800: "Ultrabold",
	900: "Black",
);

@each $weight, $name in $font-weight-list {
	@font-face
	{
		font-family: 'PP Neue Machina';
		font-weight: $weight;
		font-style: normal;

		src: url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.eot');
		src: url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.eot') format('embedded-opentype'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.woff2') format('woff2'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.woff') format('woff'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.ttf') format('truetype');
	}

	.machina-#{$weight} {
		font-weight :$weight;
	}
}
@font-face {
	font-family: 'password';
	font-style: normal;
	font-weight: 400;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

* {
	 font-family: "Helvetica Neue", "Helvetica", "Roboto";
	font-weight: normal;
	//font-family: "Arial Black";
}

.font-family-title {
	font-family: "PP Neue Machina";
}

